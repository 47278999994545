/* stylelint-disable selector-no-qualifying-type */
/* stylelint-disable length-zero-no-unit */
/* stylelint-disable declaration-property-value-disallowed-list */
/* stylelint-disable color-named */
/* stylelint-disable order/properties-alphabetical-order */
/* stylelint-disable scss/at-rule-no-unknown */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --timeit-primary-color: #74a315;
    --main-color: #22701a;
    --sub-color: #74a315;
    --sub-color-light: #a7c860;
    --sub-color-lighter: #c6ebc2;
    --sub-color-lightest: #ebf2dc;
    --main-color-light: #3d8436;
    --main-color-lighter: #8fc28a;
    --main-color-lightest: #eaf9c5;
    --background-button-login: #22701a;
    --background-footer: #74a315;
    --height-input-default: 44px;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

a {
    text-decoration: none !important;
}

.ant-switch {
    background-color: rgba(0, 0, 0, 0.25);
}

.ant-switch.ant-switch-checked {
    background-color: var(--background-footer) !important;
}


.ant-btn-primary {
    background: var(--main-color);
}

.ant-btn-primary:hover {
    background: #cc143c !important;
}

.ant-radio-inner {
    width: 28px !important;
    height: 28px !important;
    border-color: #d0d5dd !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--sub-color) !important;
    background-color: var(--sub-color-lighter) !important;
}

.ant-radio-inner::after {
    width: 28px !important;
    height: 28px !important;
    margin-block-start: -14px !important;
    margin-inline-start: -14px !important;
}

.ant-radio-checked .ant-radio-inner::after {
    background-color: var(--sub-color) !important;
}

.ant-input-affix-wrapper-disabled.disabled-bg-white,
input.disabled-bg-white {
    background-color: white !important;
}

.ant-input-affix-wrapper-disabled.disabled-bg-white input,
input.disabled-bg-white {
    color: black !important;
}

.main-menu__modal,
.custom-antd-modal-select,
.custom-antd-modal-select-bottom {
    width: 100vw !important;
    min-height: 100vh !important;
    padding-bottom: 0px;
    top: 0px;
    margin: 0;
    max-width: unset !important;
}

.custom-antd-modal-select-bottom {
    display: flex;
    align-items: flex-end;
}

.custom-antd-modal-select .ant-modal-content {
    width: 100vw;
}

.custom-antd-modal-select-bottom .ant-modal-content {
    width: 100vw !important;
}

.custom-antd-modal-select-bottom .ant-modal-content,
.main-menu__modal .ant-modal-content,
.custom-antd-modal-select .ant-modal-content {
    border-radius: 0px !important;
    padding: 0;
    background: #e9e9e9;
}

.main-menu__modal .ant-modal-close,
.custom-antd-modal-select .ant-modal-close,
.custom-antd-modal-select-bottom .ant-modal-close {
    display: none;
}

.time-modal .ant-modal-close {
    display: none;
}

.ant-input-affix-wrapper {
    padding: 8px 11px;
    color: #939393;
    font-size: 16px;
    /* border-color: #22701a; */
    border-radius: 4px;
}

/* 
.ant-select-single .ant-select-selector {
    border-radius: 2px;
} */

.ant-modal-confirm-body .anticon {
    display: none !important;
}

.ant-modal-confirm-content {
    width: 100%;
}

.ant-input:not(.text-md) {
    border-radius: 4px;
    font-size: 18px !important;
}

.ant-input-affix-wrapper {
    height: var(--height-input-default);
}


.ant-input-affix-wrapper >input.ant-input:not(:disabled){
    color: black;
}

/* .ant-select .ant-select-arrow {
    display: flex;
    align-items: center;
    color: white;
    background: var(--sub-color);
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    position: absolute;
    top: 0;
    right: 0;
    inset-inline-end: 0px;
    height: 100%;
    padding: 0 4px;
    margin-top: 0px;
    font-size: 12px;
    pointer-events: none;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

/* .ant-select-single .ant-select-selector .ant-select-selection-search {
    position: static;
    padding-right: 4px;
} */

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-inline-end: 30px;
}

/* .ant-btn-default:not(:disabled):not(.bg-green1A):not(.bg-green15) {
    color: #525252;
    border-color: #525252;
} */
.ant-btn-default.bg-green1A:not(:disabled):hover {
    background: var(--main-color) !important;
    border-color: var(--main-color) !important;
    color: white !important;
}

.ant-btn-default.bg-green1A:not(:disabled):focus {
    background: var(--main-color);
    color: white;
}

.ant-message {
    top: 14px !important;
}

.ant-message .ant-message-notice .ant-message-success>.anticon {
    color: var(--sub-color);
}

/* .ant-spin {
    color: var(--sub-color);
    display: none;
    text-align: center;
    vertical-align: middle;
    opacity: 1;
} */

.ant-spin .ant-spin-dot-item {
    background-color: var(--sub-color);
}

.ant-spin-nested-loading>div>.ant-spin {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 700px;
}

.ant-input:hover {
    border-color: var(--sub-color);
    border-inline-end-width: 1px;
}

.ant-select-selector {
    height: 100% !important;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: var(--sub-color);
}

.ant-list .ant-list-item {
    display: block;
    border: none;
    padding: 4px 0;
}

.ant-checkbox::after,
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--sub-color) !important;
}

.ant-checkbox .ant-checkbox-inner {
    border-color: var(--sub-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--sub-color) !important;
}

.ant-select .ant-select-clear {
    inset-inline-end: 30px;
}

.ant-form-item .ant-form-item-label>label {
    height: 32px;
    font-size: 20px;
}

.ant-form-item .ant-form-item-label {
    padding: 0;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 18px;
    font-family: Inter, sans-serif;
    line-height: 1;
    content: '*';
}

.ant-form-item {
    margin-bottom: 12px;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--sub-color-lightest);
}

.ant-collapse-borderless {
    background-color: #e9e9e9;
}
